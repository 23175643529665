import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectUserToken } from "../../features/user";
import serviceApi from "../../utility/api/serviceApi";
import Container from "../../components/ServiceEide/Index";
import Card from "../../components/ServiceEide/Services";
import Loading from "../../components/Loading";
import CardLink from "../../components/ServiceEide/ServiceExternal";

const ServiceById = () => {
    let { id } = useParams();
    var navigate = useNavigate();
    const token = useSelector(selectUserToken);
    const [serviceData, setServiceData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchServicos = useCallback(async (id) => {
        setLoading(true);
        var result = await serviceApi.listarServicosFilho(id, token);
        console.log(result.data);
        if (result.hasError) {
          setLoading(false);
          navigate('/service/listagem/notfound');
        } else {
          setServiceData(result.data);
          setLoading(false);
        }
        setServiceData(result.data);
        setLoading(false);
      }, [token]);

      useEffect(() => {
        fetchServicos(id);
      }, [fetchServicos, id]);

      function reaload(serviceId){
        fetchServicos(serviceId);
      }


    return(
      <div className="w-100 h-screen ml-[3.5rem] pt-20">
        <Container onClick={() => navigate(-1)} className={"max-h-[430px]"}>
          {loading && <Loading isVisible={true} />}
          {serviceData.map((servico) => (
              servico.temFilhos
              ? <Card id={servico.id} url={servico.urlImagem} title={servico.nome} subtitle={servico.descricao} key={servico.id} to={`/service/listagem/${servico.id}`} onClick={() => reaload(servico.id)} />
              : <CardLink id={servico.id} url={servico.urlImagem} title={servico.nome} subtitle={servico.descricao} key={servico.id} href={servico.link} />
          ))}
        </Container>
        <button onClick={() => navigate(-1)} data-tooltip-target="tooltip-default" className="ml-3 mt-5 px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none">Voltar</button>
      </div>           
    );
}

export default ServiceById;