import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import serviceApi from "../../utility/api/serviceApi";
import Container from "../../components/ServiceEide/Index";
import Card from "../../components/ServiceEide/Services";
import CardLink from "../../components/ServiceEide/ServiceExternal";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Chamados } from "./Chamados";


const Services = () => {
    
    const token = useSelector(selectUserToken);
    const [loading, setLoading] = useState(true);
    const [servicos, setServicos] = useState([]);
    const [input, setInput] = useState("");

    var lng = localStorage.getItem("i18nextLng");

    useEffect(() => {
      async function fetchData () {
        setLoading(true);
        var result = await serviceApi.listarServicos(token);
        if(result.hasError) {
          Swal.fire({
            title: "Atenção!",
            text: result.error.response.data.message,
            icon: "info"
          });
        } else {
          setServicos(result.data);
        }
      }
      fetchData();
      setLoading(false);
    }, [lng]);

    async function fechDataFilter(value) {
        if(value != undefined && value != "") {
          var result = await serviceApi.filtroService(value, token);
          setServicos(result.data);
        } else {
          window.location.reload();
        }
    }

    const handleChange = (value) => {
        setInput(value);
        fechDataFilter(value);
    };


    var textResultSearch = lng === "pt-BR" ? "Não foram encontrados resultados da pesquisa." : "Results not found.";
    var inputPlaceholder = lng === "pt-BR" ? "Pesquisar por Serviço ou Palavra Chave" : "Search by Services or Keywords";

    return (
      <div className="w-100 h-screen ml-[3.5rem]">
        <div className="py-4 grid grid-cols-1 pt-20 gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 pr-8 pl-4" style={{width: 100 + '%'}}>
          <div className="col-span-2 rounded-lg p-0 m-0">
            <div className="h-10 flex items-center rounded-lg bg-white px-4 p-0 m-0 hover:bg-slate-50 vg-shadown-custom">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input placeholder={inputPlaceholder} className="bg-transparent h-[100%] w-[100%] outline-none ml-3 placeholder-slate-500" value={input} onChange={(e) => handleChange(e.target.value)}/>
            </div>
          </div>
        </div>
        <Container className={"h-80"}>
          {loading && <Loading isVisible={true} />}
          {servicos.length > 0 ? 
            servicos.map((servico) => (
              servico.temFilhos
              ? <Card id={servico.id} url={servico.urlImagem} title={servico.nome} subtitle={servico.descricao} key={servico.id}  to={`/service/listagem/${servico.id}`}  /> 
              : <CardLink id={servico.id} url={servico.urlImagem} title={servico.nome} subtitle={servico.descricao} key={servico.id} href={servico.link} />
            )) : 
            <h2 className="absolute top-32 text-sm text-[#000000cb]">{textResultSearch}</h2>
          }
        </Container>
        <Chamados />
      </div>
    ); 
}





export default Services;